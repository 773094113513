/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/BlockDefault'
import HeroAlt from 'components/elements/HeroAlt'
import { Form, Formik } from 'formik'
import ApplicationForm from '../components/ApplicationForm/ApplicationForm'
import { getLanguage } from '../services/language'
import AgreementForm from '../components/AgreementForm/AgreementForm'

const Stripe = styled.div`
  background-color: ${props => props.theme.color.text.main};
  height: 1px;
  width: 100%;
`

const StyledDiv = styled.div`
  @media (min-width: 992px){
    padding: 0 5.5rem;
  }

  @media (max-width: 991px){

    & h1 {
      font-size: 30px;
    }
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
              }
            }
          }
        }

        formDescriptions: form_descriptions {
            section_one_description
            description
            section_two_description
            section_three_description
        }

        formSend: form_send {
          redirect_url {
            url
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, content, acf, yoast_meta: yoast },
  },
}) => {
  const language = getLanguage()
  const [step, setStep] = useState()
  const [status, setStatus] = useState('')

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
      />

      <section>
        <HeroAlt small image={acf.banner.image} />
      </section>

      <section className="mt-n5">
        <div className="container mt-n5 mb-5 pb-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 px-0">
              <BlockDefault>
                <StyledDiv>
                  <h1 className="color-contrast font-size-50 font-weight-xl">{title}</h1>
                  <ParseContent className="mt-4" content={content} />
                  <div className="my-4">
                    <Stripe />
                  </div>
                  <div className="mt-2 mb-5">
                    <ParseContent content={acf.formDescriptions.section_one_description} />
                  </div>
                </StyledDiv>
                <AgreementForm id={23} fields={acf.formDescriptions} fieldsSend={acf.formSend} status={status} setStatus={setStatus} defaultValues={{
                  'input_13': ' Schmidt Global Relocations',
                  'input_14': ' Relocation company',
                  'input_15': ' Woudenberg',
                }} />
              </BlockDefault>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageTemplate
