import React, { useState, useEffect } from 'react'
import { FormDuufGroup, FormDuufSubmit, useFormDuuf } from '@ubo/form-duuf'
import { graphql, useStaticQuery } from 'gatsby'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs';
import ButtonDefault from 'components/elements/ButtonDefault'
import styled from 'styled-components'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import Loading from 'components/shared/Loading'
import ParseContent from 'components/shared/ParseContent'
import Input from './Input'

export { FormDuufGroup, FormDuufSubmit, useFormDuuf }

const ButtonDefaultSubmit = styled.button`
  color: ${props => props.theme.color.text.light};

  &:hover {
    text-decoration: underline;
  }
`
const LoadingWrapper = styled.div`
  position: relative;
  height: 500px; 
`

const FormBox = styled.div`
  border: 1px solid ${props => props.theme.color.contrast};
  border-radius: 14px;
  padding: 3rem;

  @media (max-width: 991px){
    padding: 2rem 0;;
  }
`

const FormContent = styled(ParseContent)`
  & h2 {
    color: ${props => props.theme.color.text.contrast};
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;

    @media (max-width: 991px){
      font-size: 20px;
      line-height: 25px;
    }
  }

  & h2, p {
    @media (min-width: 991px){
      margin-bottom: 1.5rem;
    }
  }

  & ul {
    padding: 0;

    & li::before {
      display: none;
    }
  }

  @media (max-width: 991px){
    & table {
      width: 100%;

      & td {
        width: 50%;
      }
    }
  }
`

const AgreementForm = ({ id, fields, status, setStatus }) => {
  const { AgreementForms } = useStaticQuery(graphql`
    query AgreementFormDuufQuery {
        AgreementForms: allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            formFields {
              id
              label
              labelPlacement
              type
              defaultValue
              isRequired
              visibility
              inputName
              placeholder
              checkboxLabel
              choices
              allowedExtensions
              description
              content
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: form } = AgreementForms.edges.filter(
    ({ node: { formId } }) => formId === id
  )[0]

  const minDate = dayjs().format('YYYY-MM-DD')

  const [data, setData] = useState({
    'input_13': 'Schmidt Global Relocations',
    'input_14': 'Relocation Company',
    'input_15': 'Woudenberg',
    'input_16': minDate,
  })
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const storageData = localStorage.getItem(`quotation-${id}-data`)

      if (storageData) {
        setData(JSON.parse(storageData))
      }
    }
  }, [])

  const setDataLocal = event => {
    const newData = { ...data }

    newData[event.target.name] = event.target.value

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`quotation-${id}-data`, JSON.stringify(newData))
    }

    setData(newData)
  }


  const handleSubmission = async values => {
    const formData = new FormData()

    Object.keys(values).forEach(key => {
      formData.append(key, values[key])
    })

    try {

      const request = await fetch(
        `${form.apiURL}/submissions`,
        {
          method: 'POST',
          body: formData,
        },
      )

      setStatus('done')
      setMessage('Thanks for your message.')

      localStorage.removeItem(`quotation-${id}-step`)
      localStorage.removeItem(`quotation-${id}-expanded`)
      localStorage.removeItem(`quotation-${id}-data`)

      // if (request.ok) {
      //   setStatus('redirecting')
      //   await request.json()

      //   localStorage.removeItem(`quotation-${id}-step`)
      //   localStorage.removeItem(`quotation-${id}-expanded`)
      //   localStorage.removeItem(`quotation-${id}-data`)

      //   return true
      // } 

      // const error = await request.json()
      // await setStatus(error)

      // return false
    } catch (error) {
      // setStatus(error)
      // throw error
      setStatus('done')
      setMessage('Thanks for your message.')
    }
  }

  const formFields = {}
  const initialValues = {
    'input_13': 'Schmidt Global Relocations',
    'input_14': 'Relocation Company',
    'input_15': 'Woudenberg',
    'input_16': minDate,
  }
  let formFieldsIndex = 1
  let storageData = null


  if (typeof localStorage !== 'undefined') {
    storageData = localStorage.getItem(`quotation-${id}-data`)
  }

  if (storageData) {
    storageData = JSON.parse(storageData)
  }

  form.formFields.forEach(edges => {
    if (edges.type === 'section') {
      formFieldsIndex = parseFloat(edges.label.split(' ')[1])
    } else {
      if (!formFields[formFieldsIndex]) {
        formFields[formFieldsIndex] = []
      }

      formFields[formFieldsIndex].push(edges)

      if (storageData && storageData[`input_${edges.id}`]) {
        initialValues[`input_${edges.id}`] = storageData[`input_${edges.id}`]
      } else {
        initialValues[`input_${edges.id}`] = edges.defaultValue
      }
    }
  })

  return (
    <div className="px-lg-4">
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors = {}

          form.formFields.forEach(item => {
            if (
              values[`input_${item.id}`] !== undefined &&
              values[`input_${item.id}`] === '' &&
              item.isRequired
            ) {
              errors[`input_${item.id}`] = 'Is required'
            }

            if (
              values[`input_${item.id}`] !== undefined &&
              values[`input_${item.id}`] !== '' &&
              item.type === 'email' &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[`input_${item.id}`])
            ) {
              errors[`input_${item.id}`] = 'Not a valid e-mailadres'
            }

            if (
              values[`input_${item.id}`] !== undefined &&
              values[`input_${item.id}`] !== '' &&
              item.type === 'phone' &&
              !isPossiblePhoneNumber(values[`input_${item.id}`])
            ) {
              errors[`input_${item.id}`] = 'Invalid phone number, please choose a valid country'
            }

            if (
              values[`input_${item.id}`] !== undefined &&
              values[`input_${item.id}`] !== '' &&
              item.class === 'react-datepicker'
            ) {
              errors[`input_${item.id}`] = 'Date must be later than today'
            }
          })

          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          const response = await handleSubmission(values)

          if (!response) {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, setFieldValue, validateForm, handleSubmit }) => {
          if (isSubmitting || status === 'redirecting') {
            return (
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            )
          }

          if (status === 'done') {
            return (
              <ParseContent content={message} />
            )
          }

          return (
            <Form method="post" id={`form_${id}`}>

              <FormBox className="my-5">
                {formFields[1].map(item => <Input key={item.id} item={item} setFieldValue={setFieldValue} initialValues={initialValues} setData={setDataLocal} data={data} />)}
              </FormBox>


              <div className="px-lg-5 mx-lg-5 mb-lg-5">
                <FormContent content={fields.description} />
              </div>

              <div className="px-lg-5 mx-lg-5">
                <FormContent content={fields.section_two_description} />
              </div>

              <FormBox className="my-3">
                {formFields[2].map(item => <Input key={item.id} item={item} setFieldValue={setFieldValue} initialValues={initialValues} setData={setDataLocal} data={data} />)}
              </FormBox>

              <div className="px-lg-5 mx-lg-5">
                <FormContent content={fields.section_three_description} />
              </div>

              <FormBox className="my-3">
                {formFields[3].map(item => <Input key={item.id} item={item} setFieldValue={setFieldValue} initialValues={initialValues} setData={setDataLocal} data={data} />)}
              </FormBox>

              <div className="d-flex justify-content-end">
                <ButtonDefault
                  type="next"
                  isCustom
                >
                  <ButtonDefaultSubmit
                    type="submit"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Submit form
                  </ButtonDefaultSubmit>
                </ButtonDefault>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AgreementForm